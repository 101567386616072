.btn-menu {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
}
.btn-menu .btn {
    text-align: center;
}

.table_wrapper {
    overflow: auto;
    margin-bottom: 20px;
    height: 55vh;
}

table.excel_preview {
    .excel_value {
        min-width: 250px;
    }
}

table input.row-input {
    text-align: center;
    background-color: hsl(0, 0%, 100%);
    border-radius: 4px;
    border: 1px solid hsl(0, 0%, 80%) !important;
    border-width: 1px;
    height: 38px;
    padding: 0 20px;
}

table input.row-input.invalid,  table div.invalid .css-1s2u09g-control {
    border: 1px solid hsl(0, 61%, 45%) !important;
    background-color: hsl(0, 100%, 95%);
}

table tr.disabled {
    background-color: hsl(0, 0%, 90%);
    input.row-input, .css-1insrsq-control {
        border: 1px solid hsl(0, 0%, 80%) !important;
        background-color: hsl(0, 0%, 90%);
        color: hsl(0, 0%, 73%);
    }
    .css-lr9fot-singleValue {
        color: hsl(0, 0%, 73%);
    }
}

table a.cancel-link {
    color: #f45050;
    margin-left: 15px;
}

.title {
    display: flex;
    align-items: center;
    margin: 10px 0px;
    gap: 20px;
}

.popupForm > .fieldset-wrapper {
    display: flex;
    justify-content: space-between;
}

.popupForm fieldset {
    padding: 20px;
}

.popupForm button[type="submit"] {
    float: right;
}

#import-file-form fieldset {
    display: inline-flex;
    width: 325px;
}

#import-file-form fieldset {
    display: inline-flex;
    width: 325px;
}

#import-file-form #select-file {
    width: 250px
}

#import-file-form #select-file > strong {
    text-align: center;
    margin: auto;
}


.modal input.large {
    width: 500px;
    margin-left: 20px;
}

#import-file-form {
    input#header, select#csv_sep {
        width: 50px;
        margin-left: 20px;
        text-align: center;
    }
}