/** Var */
$bg-light: #f4f6f6;
$bg-dark: #1c1c24;

$text-color: #171725;
$text-light-color: #fff;
$text-grey: #696974;

$turquoise: #2abdbd;
$pink: #e02f84;
$blue: #0062ff;
$blue-medium: #1e75ff;

$border-color: #e2e2ea;
$transition-duration: 0.2s;
$loader-color: $bg-dark;
$loader-color: $turquoise;

// navigation
$btn-colors: ("pink": $pink,
	"turquoise": $turquoise,
	"blue": $blue,
);
$li-activ-color: $blue;
$li-hover-color: $blue;

// dashboard blocs
$bloc-background: #fff;
$bloc-border-radius: 1.425rem;

// alert cards
$card-border-radius: 1.5rem;
$card-bg: #2e2a33;;
/** Var */

.rcs-custom-scroll {

	.rcs-custom-scrollbar {
		opacity: 1 !important;
	}

	.rcs-inner-handle {
		height: calc(100% - 12px) !important;
		margin-top: 6px !important;
		background-color: #f3f3f4;
		background-color: #e2e2e3 !important;
		border-radius: 3px !important;
	}
}

#alerte_en_cours {
	padding-bottom: 1.4rem;
	height: 524px !important;
	position: relative;

	&.scrollable {
		.alert-card {
			margin-right: 0.9rem;
			margin-left: 0.6rem;
		}
	}

	.rcs-custom-scroll .rcs-inner-handle {
		opacity: 0.5;
	}
}

.alert-card {
	border-radius: $card-border-radius;
	background: $card-bg;
	margin: 0 5px 0 5px;
	display: flex;
	cursor: pointer;

	@at-root #alerte_archivees #{&} {
		cursor: default;

		&:hover {
			background: $card-bg;

			.card-description {
				color: #92929d;
			}
		}
	}

	transition: all $transition-duration;

	.card-images {
		flex: 0 0 2.8rem;
		display: flex;
		flex-direction: column;
		align-items: center;

		img {
			max-width: 100%;
			height: auto;
			border-radius: 50%;
		}

		.icon {
			font-size: 1.1rem;
		}

		@at-root .community-img {
			width: 100%;
			//height: 2.8rem;
			border-radius: 50%;
			//margin-bottom: 1rem;
		}

		@at-root div.community-img {
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 1.4rem;
			color: #fff;
		}
	}

	.card-infos {
		flex: 0 0 calc(100% - 2.8rem);
		padding-left: 0.67rem;
		overflow: hidden;
	}

	.card-top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 0.33rem;
	}

	.card-communities {
		color: #8fb2ea;
		line-height: 1em;
	}

	.card-duration {
		font-size: 0.87rem;
	}

	.card-title {
		font-family: "Poppins", sans-serif;
		font-weight: 600;
		font-size: 0.87rem;
		color: #fafafb;
		margin-bottom: 0.5rem;
	}

	.project-details {
		color: #92929d;
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	&:hover {
		background: lighten($card-bg, 10);

		.project-details {
			color:#fff;
		}

	}

	&.active {
		background: $blue-medium;
		cursor: pointer;
		filter: drop-shadow(0px 4px 14px #000000);

		.user-avatar,
		[class^="user-avatar"]:not([class*="-group"]) {
			border-radius: 50%;
			height: 40px;
			width: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
			color:#fff;
			background:#798bff;
			font-size: 14px;
			font-weight: 500;
			letter-spacing: 0.06em;
			flex-shrink: 0;
			position: relative;
			border: 2px solid #fff;
		}

		.project-head {
			color: #ffffff;
		}

		.project-details {
			color: #fff;
			opacity: 0.7;
		}
	}
}

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
	z-index: 10000;
}

.modal-main {
	position: fixed;
	background: white;
	width: 32.597rem;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 2rem;
	overflow: hidden;
	text-align: center;

	.modal-img {
		width: 100%;
		height: 14rem;
		overflow: hidden;
		background: $bg-dark;

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	.modal-inner {
		padding: 3rem 2rem 2rem;
	}

	@at-root .display-block {
		display: block;
	}

	@at-root .display-none {
		display: none;
	}

	[class^="btn-"] {
		display: inline-flex;
		margin-left: auto;
		margin-right: auto;
	}

}

.titleModal {
	color: black;
	text-align: left;
	font-family: Poppins, Arial, sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 2rem;
	line-height: 1em;
}

.descModal {
	color: $text-color;
	font-family: Roboto, Arial, sans-serif;
	font-style: normal;
	font-weight: normal;
	line-height: 1.4em;
	letter-spacing: 0.01rem;
/** margin: 2rem 0 2rem;
	font-size: 1rem; 
	margin:1rem 0 1rem; */
	margin:1rem 1rem 1rem 1rem;
	text-align: left;
	height: auto;

	p:not(:last-child) {
		margin-bottom: 0.67rem;
	}
	
}

.item_Commu {

	display: flex;

	&:not(:last-child) {
		margin-bottom: 0.67rem;
	}

}

.number_commu_type {
	font-family: Roboto, Arial, sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 0.93rem;
	line-height: 1rem;
	letter-spacing: 0.006rem;
	color: $text-color;
	margin-right: 0.3rem;
}

.commu_type {
	font-family: Roboto, Arial, sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 0.93rem;
	line-height: 1rem;
	letter-spacing: 0.006rem;
	color: $text-grey;
}

.rcs-custom-scroll {
	.rcs-custom-scrollbar {
		opacity: 1;
	}
	.rcs-inner-handle {
		height: calc(100% - 12px);
		margin-top: 6px;
		background-color: #e2e2e3;
		border-radius: 3px;
	}
}

.leaflet-interactive {
	background-color: #0062ff1a;
	stroke-width: 1;
}

.nb_people_affected {
	padding: 20px;
	font-size: 1.05rem;
}

.nb_people_affected.nobody {
	color: #ce4040;
	position: relative;
	top: 3px;
}

.nb_people_affected .bold {
	font-weight: bold;
	font-size: 1.1rem;
}

#add_alert input.invalid,  #add_alert #editor-wrapper.invalid .description-editor {
    border: 1px solid hsl(0, 61%, 45%) !important;
    background-color: hsl(0, 100%, 95%);
}
.hidden {
	display: none !important;
}