/** var */

/** background-color */
$bg-light: #F4F6F6;
$bg-dark: #1C1C24;

/** text-color */
$text-color: #171725;
$text-light-color: #fff;
$text-grey: #696974;

/** text-color */
$turquoise: #2ABDBD;
$pink: #E02F84;
$blue: #0062FF;
$blue-medium: #1E75FF;

/** border & loader */
$border-color: #E2E2EA;
$transition-duration: 0.2s;
$loader-color: $bg-dark;
/** $loader-color: $turquoise; */

/** navigation */
$btn-colors: ('pink': $pink, 'turquoise': $turquoise, 'blue':$blue );
$li-activ-color: $blue;
$li-hover-color: $blue;

/** dashboard blocs */
$bloc-background: #fff;
$bloc-border-radius: 1.425rem;

/** alert cards */
$card-border-radius: 1.5rem;
$card-bg: #292232;

/** var */

#mapwidget {
	position: relative;
}

.MapContent {
	border-radius: 1.425rem 1.425rem 0 0;
}

.custom-icon {
	background: $blue;
	border: 3px solid #FFFFFF;
	border-radius: $bloc-border-radius;
	box-sizing: border-box;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.banner {
	display: flex;
	height: 4.591rem !important;
	align-items: center;
	background: $bloc-background;
	border-radius: 0 0 $bloc-border-radius $bloc-border-radius;
}

.cityTile {
	font-family: Poppins, sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 1.333rem;
	line-height: 2rem;
	letter-spacing: 0.007rem;
	color: $text-color;
	font-size: 11px;
}

.bloc {
	background: $bloc-background;
	border-radius: $bloc-border-radius;
	//max-width: 18.875rem;
	width: 100%;
	flex: 1 1 100%;
	//min-height: 12rem;
	box-shadow: 0 4px 18px rgba(0, 0, 0, 0.05);

	&:not(:last-child) {
		margin-bottom: 1.73rem;
	}

	&.empty .bloc-inner {
		opacity: 0.5;
		font-style: italic;
	}
	.bloc-inner {
		padding: 1.33rem 1.73rem;
	}

	h2 {
		font-family: Poppins, Arial, sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 1.87rem;
		line-height: 1.15em;
		letter-spacing: 0.006rem;
		color: $text-color;
	}

	h3 {
		font-family: Poppins, Arial, sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 1.07rem;
		letter-spacing: 0.006rem;
		color: $text-color;
		line-height: 1.33em;
	}
}

.Location {
	position: relative;
	left: 25%;
	margin-left: auto;
	margin-right: auto;
	display: inline-flex;
	top: -3px;
}

.region {
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.1px;
	color: #696974;
	align-items: flex-end;
}
